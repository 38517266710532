@import "general";
@import "base";
// @import "components";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    background-color: $light-grayish-blue;
}
