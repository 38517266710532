.bg-soft-green {
    background-color: $very-soft-green;
}

.bg-light-yellow {
    background-color: $light-yellow;
}

.bg-light-blue {
    background-color: $light-blue;
}