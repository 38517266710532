.me-10 {
    margin-right: $ten;
}

.my-50 {
    margin-top: $fifty;
    margin-bottom: $fifty;
}

.my-26 {
    margin-top: $twenty-six;
    margin-bottom: $twenty-six;
}