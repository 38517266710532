.text-violet {
    color: $pure-violet;
}

.text-soft-red {
    color: $soft-red;
}

.text-dark-grey {
    color: $dark-grey;
}